exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-hikkoshi-js": () => import("./../../../src/pages/article/hikkoshi.js" /* webpackChunkName: "component---src-pages-article-hikkoshi-js" */),
  "component---src-pages-article-hikkoshi-line-js": () => import("./../../../src/pages/article/hikkoshi-line.js" /* webpackChunkName: "component---src-pages-article-hikkoshi-line-js" */),
  "component---src-pages-article-hikkoshi-new-js": () => import("./../../../src/pages/article/hikkoshi-new.js" /* webpackChunkName: "component---src-pages-article-hikkoshi-new-js" */),
  "component---src-pages-article-town-js": () => import("./../../../src/pages/article/town.js" /* webpackChunkName: "component---src-pages-article-town-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

